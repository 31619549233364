<!-- eslint-disable vue/html-indent -->
<template>
<div class="wrapper">
    <div class="page">
        <div id="loader"></div>
        <div class="page-inner" id="main_page">
            <div class="va-card">
                <div class="row">
                    <div class="col-md-12">
                        <div class="dash_card_body">
                            <div class="header_section">
                                <div>
                                    <router-link :to="routeLink"><i class="glyphicon glyphicon-arrow-left"></i></router-link>
                                </div>
                                <h4 class="menu_header">Logs</h4>
                            </div>
                            <div class="search_section">
                                <div class="row">
                                    <div class="col-md-6 col-sm-12">
                                        <div class="form-group has-search mt-1">
                                            <span class="fa fa-search form-control-feedback"></span>
                                            <input type="text" v-model="search" @keyup="searchLogs()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..." class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-12">
                                        <button class="btn btn_reserveport_orange mt-1">search</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <hr>
                    </div>
                    <div class="col-md-12">
                        <div class="dash_card_body">
                            <div class="review_table">
                                <div class="table_section">
                                    <table class="table  table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Changes</th>
                                                <th scope="col">Date</th>
                                                <!-- <th></th> -->
                                            </tr>
                                        </thead>
                                        <tbody v-if="!loading">
                                            <tr v-for="(log,index) in logs" :key="index">
                                                <td>{{log.user_name}}</td>
                                                <td>
                                                    <table v-if="log.changeset.length > 0">
                                                        <thead>
                                                            <th>Data</th>
                                                            <th>Changes</th>
                                                        </thead> 
                                                        <tbody>
                                                            <tr v-for="(change,idx) in log.changeset" :key="idx">
                                                                <td>{{getBranchName(change.path)}}</td>
                                                                <td><b> {{change.type}} :</b> <span v-if="change.type =='update'"><b>From:</b> {{change.from}} <b>To</b> {{change.to}}</span> <span v-else>{{change.to}}</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>{{formatDate(log.created_at,1)}}</td>
                                                <!-- <td></td> -->
                                            </tr>
                                        </tbody>
                                        <tfoot v-if="!loading">
                                            <tr>
                                                <td colspan="8">
                                                    <div id="pagination" v-if="totalPages > 1">
                                                        <ul class="showItems">
                                                            <li>Show Items:
                                                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                                                    <option :value="10">10</option>
                                                                    <option :value="20">20</option>
                                                                    <option :value="30">30</option>
                                                                    <option :value="40">40</option>
                                                                    <option :value="50">50</option>
                                                                </select>
                                                            </li>

                                                        </ul>
                                                        <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />

                                                    </div>

                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div v-if="loading" class="loading-cover">
                                    <div class="loader"> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Pagination from '@/pages/pagination/pagination.vue';
// import { useToast } from "vue-toastification";
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
// const toast = useToast();
export default {
    components: {
        Pagination,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            // configuresStores:[],
            loading: false,
            spinner: false,
            page: 1,
            logs: [],
            totalPages: 0,
            totalRecords: 0,
            recordsPerPage: 10,
            id: this.$route.params.id,
            logId: this.$route.query.logID,
            search: '',
            errors: false,
            errorMsg: [],
            routeLink:'',
            debounce: null,
            branchName:[]
        }
    },
    validations() {
        return {
            criteria: { required },
            status: { required },
            message: { required },
            customerType: { required }
        }
    },
    mounted () {        
        switch(parseInt(this.logId)){
            case 1:
                this.routeLink = {'name': 'updatemenu','params':{'menuid': this.id}};
                break;
            case 2:
                this.routeLink = {'name': 'updatecategory','params':{'categoryid': this.id}};
                break;
            case 3:
                this.routeLink = {'name': 'updateitem','params':{'itemid': this.id}};
                this.getItemById()
                break;
            case 4:
                this.routeLink = {'name': 'updatemodifier','params':{'modifierid': this.id}};
                break;
            case 5:
                this.routeLink = {'name': 'updatestore','params':{'storeid': this.id}};
                break;
            case 6:
                this.routeLink = {'name': 'managestores'};
                break;
            case 7:
                this.routeLink = {'name': 'thirdpartysettings'};
                break; 
        }
        this.getLogs();
    },
    methods: {
        searchLogs() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getLogs();
            }, 1500)
        },
        rate(star){
            this.criteria = star;
        },
        getBranchName(word){
            // \\
            if(word.includes("BranchCustomizations")){
                const myArray = word.split(".")
                myArray[1]=this.branchName[myArray[1]]
                return myArray.join(": ")
            }else if(word.includes("Branches")){
                const brancharr =  word.split(".")
                const branchName = this.branchName[brancharr[1]]
                brancharr.shift()
                brancharr[0]= branchName
                return brancharr.join(": ")
            }else{
                return word
            }

        },
        async getLogs(){
            try{
                let url = `${this.baseUrl}/activity-log`;
                url = new URL(url);
                url.searchParams.set('page', this.page);
                url.searchParams.set('size', this.recordsPerPage);
                url.searchParams.set('merchant_id', this.merchantID);
                url.searchParams.set('table', this.logId);
                url.searchParams.set('id',this.id)
                if(this.search !==''){
                    url.searchParams.set('user_name',this.search);
                }
                const response  = await this.axios.get(url,this.apiRequestConfig())
                if(response.status  === 200){
                    this.logs = response.data.data;
                    this.totalPages = Math.ceil(response.data.total / this.recordsPerPage)
                    this.totalRecords = response.data.total_count
                    //
                }
            }catch(error){
                if(error.response.status === 401){
                     this.$router.push({
                                name: 'login',
                    });

                }
            }
        },
        async getItemById() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.baseUrl + "/menu_item/" + this.id + "?merchant_id=" + this.merchantID;
            this.axios.get(url, config).then((response) => {

                if (response.status === 200) {
                    console.log("branche",response.data.branches)
                    if (response.data.branches) {
                        const branches = response.data.branches;
                        for (let x = 0; x < branches.length; x++) {
                            this.branchName[x] = branches[x].branch_name
                        }
                    }
                    
                    // this.categoryOptions = response.data.menu_item.categories
                }
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            })
        },
        updateModalData(data) {
            this.criteria = data.criteria;
            this.message = data.message;
            this.customerType = data.customer_type;
            this.status = data.status;
        },
        createModalData() {
            this.criteria = '';
            this.message = '';
            this.customerType = '';
            this.status = 1;
        },
        onPageChange(page) {
            this.page = page
            this.getLogs();
        },
        onChangeRecordsPerPage() {
            this.getLogs();
        },

    },
    mixins: [ReusableDataMixins, ReusableFunctionMixins]
}
</script>
